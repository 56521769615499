<template>
  <div>
    <input
      v-for="(vInput, index) in vModels"
      v-model.trim="vInput.value"
      type="number"
      :ref="`vf-input`"
      :key="`vf-${index}`"
      class="validation-input"
      :class="{ 'input-margin-right': index + 1 != vModels.length }"
      @input="onValidationInputChanged(index)"
      maxlength="1"
      v-mask="'#'"
      @keyup="handleKeyUp($event, index)"
      :disabled="disabled"
    />
    <!-- {{ text }} -->
  </div>
</template>

<script>
export default {
  name: "VerificationInput",
  mounted() {
    for (let index = 0; index < this.length; index++) {
      this.vModels.push({ value: "" });
    }
  },
  computed: {
    fullText() {
      return this.vModels.map((elem) => elem.value).join("");
    },
  },
  watch: {
    vModels: {
      handler(val) {
        // console.log(val);
      },
      deep: true,
    },
    fullText: {
      handler(val) {
        if (val.length == this.length) {
          this.inputDone();
        }
      },
    },
  },
  props: {
    length: {
      type: Number,
      default: 4,
    },
    handleInputDone: Function,
    disabled: Boolean,
  },
  data() {
    return {
      vModels: [],
      text: "",
    };
  },
  methods: {
    clicked() {
      this.$emit("onClick");
    },
    inputDone() {
      this.$emit("handleInputDone", this.fullText);
      // this.text = "validando...";
    },
    onValidationInputChanged(index) {
      this.$emit("handleInput", this.fullText);
      if (this.$refs["vf-input"][index].value && index < this.length - 1) {
        this.$refs["vf-input"][index + 1].focus();
      }
    },
    handleKeyUp(event, index) {
      if (event.keyCode === 8) {
        // Código para lidar com o pressionamento do Backspace aqui
        if (this.$refs["vf-input"][index - 1]) {
          this.$refs["vf-input"][index - 1].focus();
        }
      }
    },
  },
};
</script>
<style scoped>
.input-margin-right {
  margin-right: 12px;
}
input.validation-input {
  width: 50px;
  height: 46px;
  text-align: center;
  font-size: 25px;
  border-radius: 9px;
  border-width: 1px;
  border: 2px solid #ccc;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
:focus-visible {
  outline: #31b0cc auto 1px;
}
</style>
