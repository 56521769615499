import * as moment from "moment";

String.prototype.capitalizeFirst = function () {
  let res = this;
  try {
    res = this.charAt(0).toUpperCase() + this.slice(1);
  } catch (e) {
    console.log(e);
  } finally {
    return res;
  }
};
String.prototype.limitString = function (limit = 40) {
  let res = this;
  try {
    res = this.length >= limit ? `${this.substr(0, limit - 1)}...` : this;
  } catch (e) {
    console.log("erro", e);
    return res;
  } finally {
    return res;
  }
};
String.prototype.clear = function () {
  let res = this;
  try {
    res = this.replace(/[^\w\s]/gi, "")
      .replace(" ", "")
      .replace(/[_-]/g, "");
  } catch (e) {
    return res;
  } finally {
    return res;
  }
};
String.prototype.formatCPF = function () {
  let res = this;
  try {
    // Remove qualquer caractere que não seja número
    res = res.replace(/\D/g, "");
    // Aplica a formatação
    res = res.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } catch (error) {
    return res;
  } finally {
    return res;
  }
};

String.prototype.toFormattedDate = function (
  mode = 1,
  showTodayAsString = false
) {
  let res = this;
  let month;
  if (!res) return "";
  // console.log('datinha', this)
  try {
    const date = new Date(this);
    switch (mode) {
      case 1:
        // 12 de Junho
        month = moment(date).format("MMMM").capitalizeFirst();
        res = `${moment(date).format("DD [de] ")}${month}`;
        break;
      case 2:
        // 12 de Junho de 2022
        month = moment(date).format("MMMM").capitalizeFirst();
        res = `${moment(date).format("DD [de] ")}${month} de ${moment(
          date
        ).format("YYYY")}`;
        break;
    }
  } catch (e) {
    console.log(e, res);
    return res;
  } finally {
    return res;
  }
};

String.prototype.toPattern = function (mode = "cpf", customPattern = "") {
  let res = this;
  let newString = "";
  let stringIndex = 0;
  try {
    let pattern = Array(this.length).fill("#");

    switch (mode) {
      case "cpf":
        pattern = "###.###.###-##";
        break;
      case "phone":
        pattern = customPattern;
        break;
    }
    const patternCount = pattern.length;
    for (var i = 0; i < patternCount; i++) {
      if (this[stringIndex]) {
        if (pattern[i] == "#") {
          newString = `${newString}${this[stringIndex]}`;
          stringIndex++;
        } else {
          newString = `${newString}${pattern[i]}`;
        }
      }
    }
  } catch (e) {
    return res;
  } finally {
    return newString;
  }
};
