<template>
  <div class="card-header">
    <div
      class="input-group input-group-flush input-group-merge input-group-reverse"
    >
      <input
        ref="searchInput"
        class="form-control list-search"
        type="search"
        id="search-input"
        placeholder="Pesquisar"
        v-on:keyup.enter="onSearch"
      />
      <!-- v-on:input="onSearch" -->
      <span class="input-group-text">
        <i class="fe fe-search"></i>
      </span>
    </div>
    <slot name="bulkAction"></slot>
    <button @click="onSearch" class="btn btn-outline-primary btn-sm">
      Pesquisar
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typingTimer: null,
    };
  },
  mounted() {},
  methods: {
    onSearch(event) {
      console.log(this.$refs.searchInput);
      this.$emit("onSearch", this.$refs.searchInput.value);
      // clearTimeout(this.typingTimer);
      // $this.typingTimer = setTimeout(() => {
      //   $this.$emit("onSearch", event.target.value);
      // }, 200);
    },
    setFocus() {
      this.$refs.searchInput.focus();
    },
  },
};
</script>

<style></style>
