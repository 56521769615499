const paths = {
  not_found: "/not-found",
  home: "/home",
  login: "/login",
  register: "/cadastrar",
  passwordRecovery: "/esqueci-a-senha",
  passwordReset: "/password/reset",
  subscription: "/assinatura",
  plans: "/planos",
  myPlan: "/meu-plano",
  settings: "/configuracoes",
  medicines: {
    detail: "/medicamentos/detalhes",
  },
  aiChat: {
    detail: "/ia-chat/messages",
  },
  contents: {
    list: "/conteudos/lista",
    area: "/conteudos/area",
    category: "/conteudos/categoria",
    detail: "/conteudos/detalhes",
  },
  patients: {
    list: "/pacientes",
    detail: "/pacientes/detalhes",
    add: "/pacientes/adicionar",
  },
  medicalRecords: {
    validation: "/validar-prontuario",
    add: "/prontuario/adicionar",
  },
  prescriptions: {
    validation: "/validar-receita",
    add: "/receita/adicionar",
  },
  favorites: {
    list: "/favoritos",
  },
  search: {
    list: "/buscar",
  },
  aboutApp: "/sobre-o-app",
  myAccount: "/minha-conta",
  passwordUpdate: "/alterar-senha",
  inviteFriends: "/convidar-amigos",
  notifications: "/notificações",
};

export default paths;
