<template>
  <div id="app">
    <router-view />
    <NotificationToast />
    <ConfirmationAlert />
    <SubscriptionModal />
    <AboutAppModal />
  </div>
</template>

<script>
import AboutAppModal from './views/AboutApp/AboutAppModal/AboutAppModal.vue';
export default {
  name: "App",
  mounted() {
    console.log('VERSÃO DO DIAG WEB APP:', process.env.VUE_APP_ENV_STRING)
  },
  created() {
    document.title = "Diag";
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
