import NetworkService from "./NetworkService";

export default class MedicalRecordService {
  constructor() {
    this.network = new NetworkService();
  }
  create = (params) => {
    return this.network.post(this.network.endpoints.medicalRecords, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.medicalRecords, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.medicalRecords}/${id}`);
  };
  validateMedicalRecord = (params) => {
    return this.network.get(
      this.network.endpoints.validateMedicalRecord,
      params
    );
  };
}
