import store from "@/store";
import NetworkService from "./NetworkService";
import paths from "@/router/paths";

export default class AddressService {
  constructor() {
    this.network = new NetworkService();
  }
  show = (id) => {
    const sessionName = store.state.userSession;
    return Promise.resolve({
      status: true,
      data: {
        messages: [
          {
            name: sessionName,
            message:
              "Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está? Olá, como você está?asnjdnasdjajsdaisjndainsdajsdjnasjdnasjdaidajnsdkooubyasdikaokisdbu asuhdauhsdhbasduaisvdbalusdysduytasdjahbsdiuasgudaghsdjaksdhuiasdvasdjhasdhiu",
            isFromMe: true,
          },
          {
            name: sessionName,
            message: "Tudo bem, e você?",
            isFromMe: true,
          },
          {
            name: "Assistente Inteligente",
            message: "Estou ótimo, obrigado por perguntar!",
            isFromMe: false,
          },
        ],
      },
    });
  };
  create = (question) => {
    return this.network.post(this.network.endpoints.aiChat, { question });
  };
  listMessages = (chatId) => {
    return this.network.get(
      `${this.network.endpoints.aiChat}/${chatId}/messages`
    );
  };
  sendMessage = (chatId, content) => {
    return this.network.post(
      `${this.network.endpoints.aiChat}/${chatId}/messages`,
      { content }
    );
  };
  prepareMessage = (message) => {
    // message.references = 'content,Dengue,24cb25f6-cf12-4941-bc5a-b51d721731e6|drug,AAS,adb98f13-e39d-472a-a2d2-1c60aa544276'
    const session = store.state.userSession;
    const messageName =
      message.user_id == session?.id ? session?.name : "Assistente Inteligente";
    try {
      message.name = messageName;
      if (message.references) {
        message.formatted_references = message.references.split('|').map(elem => {
          const ref = elem.split(',')
          let path = ''
          let id = ref[2]
          switch (ref[0]) {
            case 'content':
              path = paths.contents.detail
              if (process.env.VUE_APP_ENV_KEY != "prod") {
                id = '20a261ca-edce-4768-b638-07dd8513f39f'
              }
              break;
            case 'drug':
              path = paths.medicines.detail
              if (process.env.VUE_APP_ENV_KEY != "prod") {
                id = '19b8c866-1ed5-4e23-a4f5-ea8fd6adbf36'
              }
              break;
          }
          return {
            type: ref[0],
            name: ref[1],
            link: `${path}/${id}`
          }
        }).filter((elem => ['content', 'drug'].includes(elem.type)))
        if (message.formatted_references?.length) {
          message.content += `
        
          Veja mais em:
            `
        }
      } else {
        message.formatted_references = []
      }
      message.message = message.content.split("\n").join("<br>");
      message.isFromMe = message.user_id == session?.id;
    } catch (e) {
      console.log(e);
    } finally {
      return message;
    }
  };
}
