import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import paths from "./paths";
import UserService from "@/services/UserService";
import qs from "qs";
Vue.use(VueRouter);

function isLogged() {
  return store.state.userSession ? true : false;
}

async function authCheck(to, from, next) {
  try {
    let filteredFullPath;
    let logged = isLogged();
    const loggedFromApi = await new UserService().loginWithBearerToken(
      to.query
    );
    console.log("res do bagui de logar", loggedFromApi);
    if (to.query.appWebView && to.query.token && to.query.user_id) {
      delete to.query.token;
      delete to.query.user_id;
      //monta a url sem o token e o user_id, pra limpar
      filteredFullPath = `${to.path}?${qs.stringify(to.query)}`;
    }
    // console.log('logged', logged)
    if (logged || loggedFromApi) {
      // Verifica se a query '?appRedirect=true' está presente na URL de origem
      if (from.query.appRedirect) {
        // Evita loop
        if (to.query.appRedirect) {
          next();
        } else {
          // Se estiver vindo do app, adiciona a flag 'appRedirect' ao objeto de rota de destino
          next({ ...to, query: { ...to.query, appRedirect: true } });
        }
      } else {
        // Se não estiver vindo do app, continua normalmente
        // se tiver os parametros token e userId, redireciona pra url filtrada, sem eles
        // senao, continua normalmente

        if (filteredFullPath) {
          next(filteredFullPath);
        } else {
          next();
        }
      }
      // next({ path: to });
    } else {
      next({ path: paths.login });
    }
  } catch (e) {
    next({ path: paths.not_found });
    console.log(e);
  }
}

const routes = [
  // LOGIN
  {
    path: paths.login,
    component: () => import("@/views/Login/Login.vue"),
    props: true,
  },

  // ESQUECI A SENHA
  {
    path: paths.passwordRecovery,
    component: () => import("@/views/PasswordRecovery/PasswordRecovery.vue"),
    props: true,
  },

  // CADASTRO
  {
    path: paths.register,
    component: () => import("@/views/Register/Register.vue"),
    props: true,
  },

  // RECADASTRAR SENHA
  {
    path: paths.passwordReset,
    component: () => import("@/views/PasswordReset/PasswordReset.vue"),
    props: true,
  },

  // HOME
  {
    path: "/",
    redirect: {
      name: "Home",
    },
    beforeEnter: authCheck,
  },
  {
    path: paths.home,
    name: "Home",
    component: () => import("@/views/Home/Home.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // PLANOS
  {
    path: paths.plans,
    component: () => import("@/views/Plans/Plans.vue"),
    props: true,
  },

  // ASSINATURA
  {
    path: paths.subscription,
    component: () => import("@/views/Subscription/Subscription.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // MINHA CONTA
  {
    path: paths.myAccount,
    component: () => import("@/views/MyAccount/MyAccount.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // ALTERAR SENHA
  {
    path: paths.passwordUpdate,
    component: () => import("@/views/PasswordUpdate/PasswordUpdate.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // MEU PLANO
  {
    path: paths.myPlan,
    component: () => import("@/views/MyPlan/MyPlan.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // SOBRE O APP
  {
    path: paths.aboutApp,
    component: () => import("@/views/AboutApp/AboutApp.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // NOTIFICAÇÕES
  {
    path: paths.notifications,
    component: () => import("@/views/Notifications/Notifications.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE PACIENTES
  {
    path: paths.patients.list,
    component: () => import("@/views/Patients/Patients.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE DO PACIENTE
  {
    path: `${paths.patients.detail}/:id`,
    component: () => import("@/views/Patients/PatientDetail/PatientDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // ADICIONAR PRONTUÁRIO
  {
    path: `${paths.medicalRecords.add}/:id`,
    component: () =>
      import("@/views/Patients/AddMedicalRecord/AddMedicalRecord.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // ADICIONAR RECEITA
  {
    path: `${paths.prescriptions.add}/:id`,
    component: () =>
      import("@/views/Patients/AddPrescription/AddPrescription.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // ADICIONAR PACIENTE
  {
    path: paths.patients.add,
    component: () => import("@/views/Patients/AddPatient/AddPatient.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // VALIDAR RECEITA
  {
    path: `${paths.prescriptions.validation}/:token?`,
    component: () =>
      import("@/views/PrescriptionValidation/PrescriptionValidation.vue"),
    props: true,
  },

  // VALIDAR PRONTUÁRIO
  {
    path: `${paths.medicalRecords.validation}/:token?`,
    component: () =>
      import("@/views/MedicalRecordValidation/MedicalRecordValidation.vue"),
    props: true,
  },

  // BUSCAR
  {
    path: paths.search.list,
    component: () => import("@/views/Search/Search.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA CONTEUDOS
  {
    path: `${paths.contents.list}/:id/:name`,
    component: () => import("@/views/Contents/Contents.vue"),
    props: true,
  },

  // DETALHE AREAS
  {
    path: `${paths.contents.area}/:id/:name`,
    component: () => import("@/views/AreaDetail/AreaDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE CATEGORIAS
  {
    path: `${paths.contents.category}/:id/:name`,
    component: () => import("@/views/CategoryDetail/CategoryDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE MEDICAMENTOS
  {
    path: `${paths.medicines.detail}/:id`,
    component: () => import("@/views/MedicineDetail/MedicineDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE CONTEUDOS
  {
    path: `${paths.contents.detail}/:id`,
    component: () => import("@/views/ContentDetail/ContentDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // FAVORITOS
  {
    path: paths.favorites.list,
    component: () => import("@/views/Favorites/Favorites.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // FAVORITOS
  {
    path: paths.inviteFriends,
    component: () => import("@/views/InviteFriends/InviteFriends.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // VIEW CONFIGURAÇÕES
  {
    path: `${paths.settings}/:key`,
    component: () => import("@/views/Settings/Settings.vue"),
    props: true,
  },

  // DETALHE DO CHAT
  {
    path: `${paths.aiChat.detail}/:id?`,
    component: () => import("@/views/AIChatDetail/AIChatDetail.vue"),
    props: true,
    beforeEnter: authCheck,
  },

  // NOT FOUND
  {
    path: "*",
    redirect: {
      name: "NotFound",
    },
  },
  {
    path: paths.not_found,
    name: "NotFound",
    component: () => import("@/views/NotFound/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  paths,
});

export default router;
